import { useCallback, useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { DehydratedState, Hydrate, QueryClient, QueryClientConfig, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Global } from '@emotion/react';
import { InjectFonts } from 'design-system/global/inject-fonts';
import { InjectIconFonts } from 'design-system/global/inject-icon-fonts';
import { GlobalCss } from 'design-system/global/global-css';
import { initMixpanel } from 'tracking/Mixpanel';
import { oTelCountVisitors, oTelCountVisits } from 'tracking/OpenTelemetry';
import {
  AuthContextProvider,
  BreakpointContextProvider,
  ModalContextProvider,
  NotificationContextProvider,
  SearchContextProvider,
  SettingsContextProvider,
} from 'context';
import ProtectedRoute from 'routes/ProtectedRoute';
import Nav from 'containers/Nav';
import Main from 'containers/Main';
import SearchScreen from 'containers/Search';
import SignInUp from 'containers/Modals/SignInUp';
import PasswordRetrieval from 'containers/Modals/PasswordRetrieval';
import ChangePassword from 'containers/Modals/ChangePassword';
import EditProfilePicture from 'containers/Modals/EditProfilePicture';
import PriceRangeWarning from 'containers/Modals/PriceRangeWarning';
import AddBookmarkToBoard from 'containers/Modals/AddBookmarkToBoard';
import CreateNewBoard from 'containers/Modals/CreateNewBoard';
import EditBoard from 'containers/Modals/EditBoard';
import DeleteBoard from 'containers/Modals/DeleteBoard';
import BoardCollaboratorsList from 'containers/Modals/BoardCollaboratorsList';
import BoardCollaboratorsInvite from 'containers/Modals/BoardCollaboratorsInvite';
import Followers from 'containers/Modals/Followers';
import Following from 'containers/Modals/Following';
import FollowedFoundries from 'containers/Modals/FollowedFoundries';
import FollowingFoundry from 'containers/Modals/FollowingFoundry';
import ContributeToFontsNinja from 'containers/Modals/ContributeToFontsNinja';
import ContributeToFontsNinjaSuccess from 'containers/Modals/ContributeToFontsNinjaSuccess';
import DeleteFontsNinjaAccount from 'containers/Modals/DeleteFontsNinjaAccount';
import DeleteAccountConfirmation from 'containers/Modals/DeleteAccountConfirmation';
import SupportedLanguageSystems from 'containers/Modals/SupportedLanguageSystems';
import FontBuyLinks from 'containers/Modals/FontBuyLinks';
import BookmarkAdded from 'containers/Notifications/BookmarkAdded';
import BoardInvitationLink from 'containers/Notifications/BoardInvitationLink';
import BoardInvitationEmailSend from 'containers/Notifications/BoardInvitationEmailSend';
import BoardJoinedByInvitationLink from 'containers/Notifications/BoardJoinedByInvitationLink';
import GdprBanner from 'containers/GDPR';
import { GOOGLE_OAUTH_CLIENT_ID } from 'common/data/Constants';
import { globalStyles } from 'styles/app.styled';

function MyApp({ Component, pageProps, router }: AppProps) {
  const defaultOptionsQueries: QueryClientConfig = {
    defaultOptions: {
      queries: {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  };

  const { dehydratedState } = pageProps as { dehydratedState: DehydratedState };
  const [queryClient] = useState(() => new QueryClient(defaultOptionsQueries));

  // Init Mixpanel
  initMixpanel();

  const handleRouteChange = useCallback((url: string) => {
    // Count OpenTelemetry visits
    oTelCountVisits();
    // Count OpenTelemetry visitors
    oTelCountVisitors();
  }, []);

  useEffect(() => {
    // Count OpenTelemetry visits
    oTelCountVisits();
    // Count OpenTelemetry visitors
    oTelCountVisitors();

    // Events when route change
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [handleRouteChange, router.events]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
        />
      </Head>
      <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={dehydratedState}>
            <BreakpointContextProvider>
              <AuthContextProvider>
                <SettingsContextProvider>
                  <ModalContextProvider>
                    <NotificationContextProvider>
                      <SearchContextProvider>
                        <Nav />
                        <Main>
                          <ProtectedRoute router={router}>
                            <Component {...pageProps} />
                          </ProtectedRoute>
                        </Main>
                        {/* SEARCH */}
                        <SearchScreen />
                        {/* /SEARCH */}
                        {/* MODALS */}
                        <SignInUp />
                        <PasswordRetrieval />
                        <ChangePassword />
                        <EditProfilePicture />
                        <PriceRangeWarning />
                        <AddBookmarkToBoard />
                        <CreateNewBoard />
                        <EditBoard />
                        <DeleteBoard />
                        <BoardCollaboratorsList />
                        <BoardCollaboratorsInvite />
                        <Followers />
                        <Following />
                        <FollowedFoundries />
                        <FollowingFoundry />
                        <ContributeToFontsNinja />
                        <ContributeToFontsNinjaSuccess />
                        <DeleteFontsNinjaAccount />
                        <DeleteAccountConfirmation />
                        <SupportedLanguageSystems />
                        <FontBuyLinks />
                        {/* /MODALS */}
                        {/* NOTIFICATIONS */}
                        <BookmarkAdded />
                        <BoardInvitationLink />
                        <BoardInvitationEmailSend />
                        <BoardJoinedByInvitationLink />
                        {/* /NOTIFICATIONS */}
                        {/* GDPR BANNER */}
                        <GdprBanner />
                        {/* /GDPR BANNER */}
                      </SearchContextProvider>
                    </NotificationContextProvider>
                  </ModalContextProvider>
                </SettingsContextProvider>
              </AuthContextProvider>
            </BreakpointContextProvider>
            <InjectFonts />
            <InjectIconFonts />
            <GlobalCss />
            <Global styles={globalStyles} />
            <ReactQueryDevtools initialIsOpen={false} />
          </Hydrate>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
