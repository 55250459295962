import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAuthContext, useSettingsContext } from 'context';
import { setItem } from 'common/utils';
import { BRIDGE_MESSAGES } from 'common/data/Bridge';
import { REFRESH_TOKEN } from 'common/data/Constants';

interface BridgeMessageEvent {
  data: {
    type: string;
    payload: Record<string, any>;
  };
}

const Bridge = () => {
  const router = useRouter();
  const { isLoggedIn, accessToken, setAccessToken, refreshToken, setRefreshToken, checkAccessToken, logout } =
    useAuthContext();
  const { darkMode, setExtensionDocumentHeight, setExtensionScrollY, setExtensionWindowHeight } = useSettingsContext();

  const [accessTokenExtension, setAccessTokenExtension] = useState<string | null>(null);
  const [refreshTokenExtension, setRefreshTokenExtension] = useState<string | null>(null);

  const getDocumentHeight = (): number => {
    // we add 2px to be sure there is no scroll inside Modal iframe
    return (document.body && document.body.scrollHeight + 2) || 0;
  };

  const handleResize = useCallback(() => {
    const documentHeight = getDocumentHeight();
    setExtensionDocumentHeight(documentHeight);
    window.parent.postMessage(
      {
        type: BRIDGE_MESSAGES.FONTS_NINJA_PAGE_HEIGHT,
        payload: {
          height: documentHeight,
        },
      },
      '*'
    );
  }, [setExtensionDocumentHeight]);

  const getFontsNinjaExtensionMessages = useCallback(
    ({ data }: BridgeMessageEvent) => {
      switch (data.type) {
        case BRIDGE_MESSAGES.FONTS_NINJA_ACCESS_TOKEN:
          if (!accessToken) {
            setAccessTokenExtension(data.payload.accessToken);
          }
          break;
        case BRIDGE_MESSAGES.FONTS_NINJA_REFRESH_TOKEN:
          if (!refreshToken) {
            setRefreshTokenExtension(data.payload.refreshToken);
          }
          break;
        case BRIDGE_MESSAGES.FONTS_NINJA_IFRAME_SCROLL_Y:
          setExtensionScrollY(data.payload.scrollY);
          break;
        case BRIDGE_MESSAGES.FONTS_NINJA_IFRAME_WINDOW_HEIGHT:
          setExtensionWindowHeight(data.payload.windowHeight);
          break;
      }
    },
    [accessToken, refreshToken, setExtensionScrollY, setExtensionWindowHeight]
  );

  useEffect(() => {
    window.parent.postMessage(
      {
        type: BRIDGE_MESSAGES.FONTS_NINJA_PAGE_LOADED,
      },
      '*'
    );
  }, []);

  useEffect(() => {
    if (router) {
      const documentHeight = getDocumentHeight();
      setExtensionDocumentHeight(documentHeight);
      window.parent.postMessage(
        {
          type: BRIDGE_MESSAGES.FONTS_NINJA_PAGE_HEIGHT,
          payload: {
            height: documentHeight,
          },
        },
        '*'
      );
    }
  }, [router, setExtensionDocumentHeight]);

  useEffect(() => {
    if (typeof darkMode !== undefined) {
      window.parent.postMessage(
        {
          type: BRIDGE_MESSAGES.FONTS_NINJA_DARK_MODE,
          payload: {
            darkMode: darkMode,
          },
        },
        '*'
      );
    }
  }, [darkMode]);

  useEffect(() => {
    if (accessToken) {
      window.parent.postMessage(
        {
          type: BRIDGE_MESSAGES.FONTS_NINJA_ACCESS_TOKEN,
          payload: {
            accessToken: accessToken,
          },
        },
        '*'
      );
    }
  }, [accessToken]);

  useEffect(() => {
    if (refreshToken) {
      window.parent.postMessage(
        {
          type: BRIDGE_MESSAGES.FONTS_NINJA_REFRESH_TOKEN,
          payload: {
            refreshToken: refreshToken,
          },
        },
        '*'
      );
    }
  }, [refreshToken]);

  // We force logout onMount when site is rendered inside extension
  useEffect(() => {
    logout();
  }, [logout]);

  // If user is not logged-in and we receive tokens from extension, we set them and logged-in user
  useEffect(() => {
    if (!isLoggedIn && accessTokenExtension && refreshTokenExtension) {
      setAccessToken(accessTokenExtension);
      setRefreshToken(refreshTokenExtension);
      checkAccessToken(accessTokenExtension);
      setItem(REFRESH_TOKEN, refreshTokenExtension);
    }
  }, [accessTokenExtension, checkAccessToken, isLoggedIn, refreshTokenExtension, setAccessToken, setRefreshToken]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('message', getFontsNinjaExtensionMessages);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('message', getFontsNinjaExtensionMessages);
    };
  }, [getFontsNinjaExtensionMessages, handleResize]);

  return null;
};

export default Bridge;
