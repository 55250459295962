import styled from '@emotion/styled';
import { mq } from 'styles/mediaqueries.styled';

export const ModalWrapper = styled.div`
  position: relative;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  /* MOBILE */
  top: -4px;
  right: -4px;
  /* TABLET */
  ${mq['tablet']} {
    top: 5px;
    right: 5px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  width: 329px;
  padding: 24px 16px 32px;
  /* TABLET */
  ${mq['tablet']} {
    width: 402px;
    padding: 24px 32px 32px;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  /* MOBILE */
  width: 342px;
  height: 110px;
  margin: 0 0 20px -20px;
  /* TABLET */
  ${mq['tablet']} {
    width: 392px;
    height: 138px;
    margin: 0 0 22px -25px;
  }
`;

export const Video = styled.video`
  /*MOBILE */
  width: 520px;
  height: 320px;
  transform: translate(0, -10px);
  /* TABLET */
  ${mq['tablet']} {
    width: 650px;
    height: 400px;
    transform: translate(0, -10px);
  }
  /* Fix border video visible on iPhone */
  mask-image: -webkit-radial-gradient(white, black);
  backface-visibility: hidden;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 17px;
`;

export const DropdownWrapper = styled.div`
  margin-bottom: 32px;
`;
