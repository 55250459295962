import { SyntheticEvent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { InputText } from 'design-system/atoms/input-text';
import { Checkbox } from 'design-system/atoms/checkbox';
import { Button } from 'design-system/atoms/button';
import { useModalContext, useSettingsContext } from 'context';
import { useFeedback } from 'hooks/queries/useFeedback';
import { isValidEmail } from 'common/utils';
import {
  ButtonSendWrapper,
  CheckboxWrapper,
  Form,
  InputFile,
  InputItem,
  InputTextWrapper,
  LI,
  ResponseError,
  UL,
} from './ListTypefacesForm.styled';

interface InputValue {
  value: string;
  error: string;
}

interface Props {
  onClose: () => void;
}

const ListTypefacesForm = ({ onClose }: Props) => {
  const { t } = useTranslation('common');
  const { setOpenModalContributeToFontsNinjaSuccess } = useModalContext();
  const { darkMode } = useSettingsContext();

  const inputFileRef = useRef<HTMLInputElement>(null);

  const [name, setName] = useState<InputValue>({ value: '', error: '' });
  const [foundry, setFoundry] = useState<InputValue>({ value: '', error: '' });
  const [email, setEmail] = useState<InputValue>({ value: '', error: '' });
  const [checked, setChecked] = useState<boolean>(false);
  const [checkboxError, setCheckboxError] = useState<boolean>(false);
  const [buttonSendDisabled, setButtonSendDisabled] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<string>('');

  const { useSendTypefaces } = useFeedback();
  const sendTypefacesMutation = useSendTypefaces({
    onSuccess: (data) => {
      if (data.success) {
        setButtonSendDisabled(false);
        onClose();
        setOpenModalContributeToFontsNinjaSuccess('list_typefaces');
      } else {
        setButtonSendDisabled(false);
        setResponseError(t('errors.generic'));
      }
    },
    onError: (err) => {
      setButtonSendDisabled(false);
      setResponseError(err.response ? err.response.data.message : t('errors.generic'));
    },
  });

  const handleChangeName = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setName({ value: event.currentTarget.value, error: '' });
  }, []);

  const handleChangeFoundry = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setFoundry({ value: event.currentTarget.value, error: '' });
  }, []);

  const handleChangeEmail = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setEmail({ value: event.currentTarget.value, error: '' });
  }, []);

  const handleChangeCheckbox = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setChecked(event.currentTarget.checked);
    setCheckboxError(false);
  }, []);

  const sendRequest = useCallback(
    (file: File) => {
      setButtonSendDisabled(true);
      sendTypefacesMutation.mutate({ fullName: name.value, foundry: foundry.value, email: email.value, file: file });
    },
    [email.value, foundry.value, name.value, sendTypefacesMutation]
  );

  const handleSubmitForm = useCallback(
    (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      setResponseError('');
      let nbError = 0;
      if (name.value.trim() === '') {
        setName({ value: name.value, error: t('contribute-to-fonts-ninja.mandatory') });
        nbError += 1;
      }
      if (!isValidEmail(email.value.trim())) {
        setEmail({ value: email.value, error: t('errors.email-error') });
        nbError += 1;
      }
      if (!checked) {
        setCheckboxError(true);
        nbError += 1;
      }
      if (nbError > 0) return;

      if (inputFileRef.current) {
        inputFileRef.current.click();
      }
    },
    [checked, email.value, name.value, t]
  );

  const handleFileChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const file: File = (event.target as any).files[0];
      const oneMegaByte = 1048576;
      let nbError = 0;
      // 100Mb
      if (file.size > oneMegaByte * 100) {
        nbError += 1;
        setResponseError(t('errors.zipfiles-too-big'));
      }

      if (nbError > 0) return;

      sendRequest((event.target as any).files[0]);
    },
    [sendRequest, t]
  );

  return (
    <>
      <UL>
        <LI>{t('contribute-to-fonts-ninja.option-1.list-1')}</LI>
        <LI>{t('contribute-to-fonts-ninja.option-1.list-2')}</LI>
      </UL>
      <Form onSubmit={handleSubmitForm}>
        <InputTextWrapper>
          <InputItem>
            <InputText
              placeholder={t('contribute-to-fonts-ninja.option-1.input-1')}
              value={name.value}
              error={name.error}
              darkMode={darkMode}
              onChange={handleChangeName}
            />
          </InputItem>
          <InputItem>
            <InputText
              placeholder={t('contribute-to-fonts-ninja.option-1.input-2')}
              value={foundry.value}
              error={foundry.error}
              darkMode={darkMode}
              onChange={handleChangeFoundry}
            />
          </InputItem>
          <InputItem>
            <InputText
              type="email"
              placeholder={t('contribute-to-fonts-ninja.option-1.input-3')}
              value={email.value}
              error={email.error}
              darkMode={darkMode}
              onChange={handleChangeEmail}
            />
          </InputItem>
        </InputTextWrapper>
        <CheckboxWrapper error={checkboxError}>
          <Checkbox
            checked={checked}
            darkMode={darkMode}
            onChange={handleChangeCheckbox}
          >
            {t('contribute-to-fonts-ninja.option-1.checkbox')}
          </Checkbox>
        </CheckboxWrapper>
        <InputFile
          ref={inputFileRef}
          type="file"
          accept=".zip,.otf,.ttf,.woff,.woff2"
          onChange={handleFileChange}
        />
        <ButtonSendWrapper>
          <Button
            disabled={buttonSendDisabled}
            darkMode={darkMode}
          >
            {t('contribute-to-fonts-ninja.option-1.button')}
          </Button>
        </ButtonSendWrapper>
        {responseError && responseError !== '' && <ResponseError>{responseError}</ResponseError>}
      </Form>
    </>
  );
};

export default ListTypefacesForm;
