import { css } from '@emotion/react';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { DESKTOP, DESKTOP_1200, DESKTOP_1440, MOBILE, TABLET } from 'common/data/Constants';
import { mq } from './mediaqueries.styled';

export const globalStyles = css`
  html,
  body {
    padding: 0;
    margin: 0;
  }

  body {
    background-color: ${WHITE};
    font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: ${GREY_6};
    margin: 0 auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.dark-mode {
      background-color: ${GREY_6};
      color: ${WHITE};
    }
    // Extension
    &.extension {
      nav {
        display: none;
      }
      main {
        padding-top: 72px;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  body:before {
    content: '${MOBILE}';
    display: none;
    ${mq['tablet']} {
      content: '${TABLET}';
    }
    ${mq['desktop']} {
      content: '${DESKTOP}';
    }
    ${mq['desktop_1200']} {
      content: '${DESKTOP_1200}';
    }
    ${mq['desktop_1440']} {
      content: '${DESKTOP_1440}';
    }
  }
`;
